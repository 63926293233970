import updatePassword from "@src/api/account/passwordChange";
import ChangeAlert from "@src/components/elements/ChangeAlert";
import Button from "@src/components/elements/input/Button";
import Input from "@src/components/elements/input/Input";
import useChangeMutation from "@src/hooks/useChangeMutation";
import AuthWrapper from "@src/pages/auth/AuthWrapper";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ChangePasswordContainer = () => {
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const lastChangeDate = null;
  const [buttonsIsDisabled, setButtonsIsDisabled] = useState(true);
  const { t } = useTranslation();
  const lastChangePlaceholder =
    "" +
    (lastChangeDate
      ? t("main.passwordChange.currentPasswordPlaceholder", {
          date: lastChangeDate,
        })
      : "");

  const {
    mutation: changePasswordMutation,
    showAlert,
    setShowAlert,
    isSuccess,
  } = useChangeMutation(updatePassword, "password-change", {
    onSettled: () => {
      setCurrentPassword("");
      setNewPassword("");
    },
  });

  useEffect(() => {
    setButtonsIsDisabled(
      newPassword.trim() === currentPassword.trim() ||
        !isValidPassword(newPassword) ||
        !isValidPassword(currentPassword),
    );
  }, [newPassword, currentPassword]);

  const isValidPassword = (password: string) => {
    const passwordRegex = /^[a-z0-9@&$!#?]{8,32}$/gi;
    return passwordRegex.test(password);
  };

  if (changePasswordMutation.isPending) {
    return <div>{t("main.loading")}</div>;
  }

  return (
    <AuthWrapper>
      <div className="mb-4 text-xl font-medium text-darkblue">
        {t("main.passwordChange.title1")}
      </div>
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Input
          name="current password"
          label={t("main.passwordChange.currentPassword")}
          value={currentPassword}
          placeholder={lastChangePlaceholder}
          type="password"
          onChange={e => setCurrentPassword(e)}
        />
        <Input
          name="new password"
          label={t("main.passwordChange.newPassword")}
          value={newPassword}
          type="password"
          minLength={8}
          maxLength={32}
          pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$"
          onChange={e => setNewPassword(e)}
        />
        <ul className="relative ml-5 mt-3 text-xs">
          <li>{t("main.panel.profileWidgets.changePasswordTooltip1")}</li>
          <li>{t("main.panel.profileWidgets.changePasswordTooltip2")}</li>
        </ul>
        <div className="mx-auto mt-2 flex gap-x-4">
          <Button
            disabled={buttonsIsDisabled}
            onClick={() =>
              !changePasswordMutation.isPending &&
              changePasswordMutation.mutate({
                data: {
                  old_password: currentPassword,
                  new_password: newPassword,
                },
              })
            }
          >
            {t("main.passwordChange.changePassword")}
          </Button>
        </div>
      </form>

      {showAlert && (
        <ChangeAlert
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          isSuccess={isSuccess}
          type="password"
        />
      )}
    </AuthWrapper>
  );
};

export default ChangePasswordContainer;
