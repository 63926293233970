import api, { RequestWithData } from "@src/api/api";

export type PasswordData = {
  old_password: string;
  new_password: string;
};

export type PasswordChangeRequest = RequestWithData<PasswordData>;

export default async function updatePassword(request: PasswordChangeRequest): Promise<void> {
  await api.patch("/api/v1/users/account/password", request.data);
}
