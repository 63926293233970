import updateUsername from "@src/api/account/usernameChange";
import ChangeAlert from "@src/components/elements/ChangeAlert";
import Button from "@src/components/elements/input/Button";
import Input from "@src/components/elements/input/Input";
import useChangeMutation from "@src/hooks/useChangeMutation";
import AuthWrapper from "@src/pages/auth/AuthWrapper";
import { useAppDispatch, useAppSelector } from "@src/state/hooks";
import { setUser } from "@src/state/user/userSlice";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ChangeUsernameContainer = () => {
  const [currentUsername, setCurrentUsername] = useState<string>("");
  const [newUsername, setNewUsername] = useState<string>("");
  const { t } = useTranslation();
  const { user } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const [usernameChangeCount, setUsernameChangeCount] = useState(3);
  const [buttonsIsDisabled, setButtonsIsDisabled] = useState(true);

  useEffect(() => {
    if (user) {
      const fetchedUsernameChangeCount = user.username_change_count || 0;
      setCurrentUsername(user.username);
      setUsernameChangeCount(fetchedUsernameChangeCount);
    }
  }, [user]);

  const {
    mutation: changeUsernameMutation,
    showAlert,
    setShowAlert,
    isSuccess,
  } = useChangeMutation(updateUsername, "update-username", {
    onSettled: () => {
      setNewUsername("");
    },
    onSuccess: updatedUser => {
      dispatch(setUser(updatedUser));
    },
  });

  useEffect(() => {
    setButtonsIsDisabled(
      usernameChangeCount >= 3 ||
        newUsername.trim() === currentUsername.trim() ||
        !validateUsername(newUsername) ||
        newUsername.length === 0 ||
        newUsername.length < 3 ||
        newUsername.length > 32,
    );
  }, [newUsername, currentUsername, usernameChangeCount]);

  const validateUsername = (newUsername: string) => {
    const trimmedUsername = newUsername.trim().toLowerCase();
    const regex = /^(?!.*--)[a-z0-9-]+$/gi;
    return regex.test(trimmedUsername);
  };

  if (changeUsernameMutation.isPending) {
    return <div>{t("main.loading")}</div>;
  }

  if (!user) {
    return <></>;
  }

  return (
    <AuthWrapper>
      <div className="mb-4 text-xl font-medium text-darkblue">
        {t("main.usernameChange.title1")}
      </div>
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Input
          name="username"
          label={t("main.usernameChange.newUsername")}
          value={newUsername}
          placeholder={currentUsername}
          type="text"
          onChange={e => setNewUsername(e)}
        />
        <ul className="relative ml-5 mt-3 text-xs">
          <li>{t("main.panel.profileWidgets.changeUsernameTooltip1")}</li>
          <li>{t("main.panel.profileWidgets.changeUsernameTooltip2")}</li>
          <li>{t("main.usernameChange.nameChangeLimit", { count: 3 - usernameChangeCount })}</li>
        </ul>
        <div className="mx-auto mt-2 flex gap-x-4">
          <Button
            disabled={buttonsIsDisabled}
            onClick={() =>
              !changeUsernameMutation.isPending &&
              changeUsernameMutation.mutate({
                data: {
                  username: newUsername,
                },
              })
            }
          >
            {t("main.usernameChange.changeUsername")}
          </Button>
        </div>
      </form>

      {showAlert && (
        <ChangeAlert
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          isSuccess={isSuccess}
          type="username"
        />
      )}
    </AuthWrapper>
  );
};

export default ChangeUsernameContainer;
