import api, { RequestWithData } from "@src/api/api";

export type UsernameData = {
  username: string;
};

export type UsernameUpdateRequest = RequestWithData<UsernameData>;

export default async function updateUsername(request: UsernameUpdateRequest): Promise<void> {
  await api.patch("/api/v1/users/username", request.data);
}
