import deactivate from "@src/api/auth/deactivate";
import Panel from "@src/components/box/Panel";
import Alert from "@src/components/elements/Alert";
import Button from "@src/components/elements/input/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AccountDeactivationPage = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);

  const logoutRequest = () => {
    setShowAlert(true);
  };

  const deactivateMutation = useMutation({
    mutationKey: ["deactivate"],
    mutationFn: deactivate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
      navigate("/logout");
    },
    onError: () => {
      alert("Failed");
    },
  });

  const handleYesClick = () => {
    deactivateMutation.mutate();
  };

  return (
    <Panel title={t("pages.accountDeactivation.title")} mobileBorder={false}>
      <div className="confirmation-container text-sm">
        <p className="mb-2">{t("pages.accountDeactivation.text1")}</p>
        <ul className="mb-2 list-inside">
          <li>
            <strong>{t("pages.accountDeactivation.item1")}:</strong>
          </li>
          <li className="mb-2">{t("pages.accountDeactivation.item11")}</li>
          <li>
            <strong>{t("pages.accountDeactivation.item2")}:</strong>
          </li>
          <li className="mb-2">{t("pages.accountDeactivation.item22")}</li>
          <li>
            <strong>{t("pages.accountDeactivation.item3")}:</strong>
          </li>
          <li className="mb-2">{t("pages.accountDeactivation.item33")}</li>
        </ul>
        <p className="mb-2">{t("pages.accountDeactivation.text2")}</p>
        <Button variant={Button.Variants.Red} onClick={logoutRequest}>
          {t("pages.accountDeactivation.deactivate")}
        </Button>
      </div>
      {showAlert && (
        <Alert
          onClose={() => {
            setShowAlert(false);
          }}
          buttons={Alert.ButtonVariants.YesNo}
          variant={Alert.MessageVariants.Warning}
          message={t("main.alerts.deactivateTitle")}
          title={t("main.alerts.deactivateText")}
          yesClick={handleYesClick}
          noClick={() => setShowAlert(false)}
        />
      )}
    </Panel>
  );
};

export default AccountDeactivationPage;
